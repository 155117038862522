<template>
  <b-modal
    id="newInviteModal"
    centered
    hide-footer
    header-bg-variant="white"
    no-body
    no-close-on-backdrop
  >
    <h3 class="mb-50">Convidar usuário</h3>
    <h5 class="mb-2">Informe abaixo os dados do convidado.</h5>
    <div class="d-flex flex-column justify-content-center align-items-start">
      <invite-form v-model="invite" :saveInvite="createInvite" :closeModal="closeModal" />
    </div>
  </b-modal>
</template>

<script>
import InviteForm from '@/views/admin/users/components/InviteForm.vue'

export default {
  name: 'NewInviteModal',
  components: {
    InviteForm,
  },
  data() {
    return {
      invite: {
        nome: '',
        email: '',
        telefone: '',
        cedente_id: '',
        tipo: ''
      },
    }
  },
  methods: {
    async createInvite() {
      await this.$store.dispatch('admin/createInvite', this.invite)
      this.$bvModal.hide('newInviteModal')
      this.$swal.fire({
        title: 'Sucesso!',
        text: 'Convite enviado com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
      this.invite = {}
      this.$emit('invite-created')
    },
    closeModal() {
      this.$bvModal.hide('newInviteModal')
      this.invite = {}
    },
  },
}
</script>
